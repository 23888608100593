<template lang="pug">
	.index
		h1 Demo Dashboard
</template>

<script>
export default {
	data() {
		return {

		}
	},
}
</script>

<style lang="scss">
.index {
	max-width: 1280px;
	margin: 0 auto;
	padding: 20px;
}
</style>